
<template>
  <div class="workbench">
    <!-- <img src="../../assets/image/数据内容空 1.png" alt="" />
    <div class="workbench_trips">
      <span>该功能正在开发中...敬请期待</span>
    </div> -->
    <div class="top">
      <div class="login_info">
        <img src="../../assets/image/Group 2821.png" alt="" />
        <div class="info">
          <h2>HI，欢迎登录{{ zcName }}管理后台</h2>
          <div class="time">
            <span class="info_icon">
              <i class="el-icon-refresh"></i>
            </span>
            <span>最新更新时间：{{ cockpitData.time }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="income">
          <div class="income_title">
            <span>
              <img src="../../assets/image/Vector (3).png" alt="" style="margin-right: 4px" />
            </span>
            <span>累计收益</span>
          </div>
          <div class="income_money">
            <span class="money_icon"
              >￥{{ cockpitData.totalIncome == null ? 0 : cockpitData.totalIncome }}</span
            >
            元
          </div>
          <div class="income_order">
            关联订单（份）{{
              cockpitData.associateOrderNum == null ? 0 : cockpitData.associateOrderNum
            }}
          </div>
        </div>
        <div class="reporting_success">
          <div class="reporting_title">
            <span class="report_icon">
              <img src="../../assets/image/Vector (8).png" alt="" style="margin-right: 4px" />
            </span>
            <span class="report_title">报备成功企业共</span>
          </div>
          <div class="reporting_num">
            <span>{{ cockpitData.reportSuccessNum }}</span>
            家
          </div>
          <div class="reporting_order">报备中企业（家）{{ cockpitData.reportIngNum }}</div>
        </div>
      </div>
    </div>
    <div class="dataTxt">
      <ul>
        <li>
          <div class="icon">
            <img src="../../assets/image/Vector (4).png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">客户数据</span>
            <div class="numInfo">
              <span ref="num4" class="num">{{
                cockpitData.companyNum == null ? 0 : cockpitData.companyNum
              }}</span>
              <span>
                <span ref="add4" class="add">本周新增</span>
                <span ref="addNum4" class="addNum">{{
                  cockpitData.companyMonthNum == null ? 0 : cockpitData.companyMonthNum
                }}</span>
                <span class="addIcon">
                  <i ref="addIcon_i4" class="el-icon-top addIcon_i"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="../../assets/image/Vector (5).png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">用户数据</span>
            <div class="numInfo">
              <span ref="num3" class="num">{{
                cockpitData.userNum == null ? 0 : cockpitData.userNum
              }}</span>
              <span>
                <span ref="add3" class="add">本周新增</span>
                <span ref="addNum3" class="addNum">{{
                  cockpitData.userMonthNum == null ? 0 : cockpitData.userMonthNum
                }}</span>
                <span class="addIcon">
                  <i ref="addIcon_i3" class="el-icon-top addIcon_i"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="../../assets/image/Group 3124.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">服务数据</span>
            <div class="numInfo">
              <span ref="num2" class="num">{{
                cockpitData.serviceNum == null ? 0 : cockpitData.serviceNum
              }}</span>
              <span>
                <span ref="add2" class="add">本周新增</span>
                <span ref="addNum2" class="addNum">{{
                  cockpitData.serviceMonthNum == null ? 0 : cockpitData.serviceMonthNum
                }}</span>
                <span class="addIcon">
                  <i ref="addIcon_i2" class="el-icon-top addIcon_i"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="../../assets/image/Vector (6).png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">诊断数据</span>
            <div class="numInfo">
              <span ref="num1" class="num">{{
                cockpitData.diagnosisNum == null ? 0 : cockpitData.diagnosisNum
              }}</span>
              <span>
                <span ref="add1" class="add">本周新增</span>
                <span ref="addNum1" class="addNum">{{
                  cockpitData.diagnosisMonthNum == null ? 0 : cockpitData.diagnosisMonthNum
                }}</span>
                <span class="addIcon">
                  <i ref="addIcon_i1" class="el-icon-top addIcon_i"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="../../assets/image/Vector (7).png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">订单数据</span>
            <div class="numInfo">
              <span ref="num" class="num">{{
                cockpitData.orderNum == null ? 0 : cockpitData.orderNum
              }}</span>
              <span>
                <span ref="add" class="add">本周新增</span>
                <span ref="addNum" class="addNum">{{
                  cockpitData.orderMonthNum == null ? 0 : cockpitData.orderMonthNum
                }}</span>
                <span class="addIcon">
                  <i ref="addIcon_i" class="el-icon-top addIcon_i"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="dataEcharts">
      <div ref="divOne" class="userData">
        <div class="data_title">
          <div class="data">用户数据</div>
          <div class="date">
            <!-- <div class="year" :class="{ active: isActive1 }" @click="checkYearEchart1">年</div> -->
            <div class="month" :class="{ active: isActive2 }" @click="checkMonthEchart1">月</div>
            <div class="day" :class="{ active: isActive3 }" @click="checkDayEchart1">近30天</div>
          </div>
        </div>
        <div class="data_echarts" ref="echarts1"></div>
      </div>
      <div ref="divTwo" class="serviceData">
        <div class="data_title">
          <div class="data">服务数据</div>
          <div class="date">
            <!-- <div class="year" :class="{ active: isActive4 }" @click="checkYearEchart2">年</div> -->
            <div class="month" :class="{ active: isActive5 }" @click="checkMonthEchart2">月</div>
            <div class="day" :class="{ active: isActive6 }" @click="checkDayEchart2">近30天</div>
          </div>
        </div>
        <div class="data_echarts" ref="echarts2"></div>
      </div>
    </div>
    <div class="quickEntrance" @click="toDiagnosis">
      <h2>快捷入口</h2>
      <div class="content">
        <div class="icon">
          <i class="el-icon-star-on"></i>
        </div>
        <div class="service">
          <span>诊断服务</span>
          <span style="margin-top: 10px;">
            今日生成
            <i>0</i>
            份报告
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入echarts
import * as echarts from 'echarts'
import { getCockpitData, getUserCurveChart, getServiceDataResult } from '../../api/cockpit.js'
export default {
  name: 'cockpit',
  data() {
    return {
      // 工作台数据
      cockpitData: {
        companyNum: null,
        companyMonthNum: null,
        userNum: null,
        userMonthNum: null,
        serviceNum: null,
        serviceMonthNum: null,
        diagnosisNum: null,
        diagnosisMonthNum: null,
        orderNum: null,
        orderMonthNum: null,
        time: null,
        totalIncome: null,
        associateOrderNum: null,
        reportSuccessNum: null,
        reportIngNum: null,
        todayDiagnoseNum: null
      },
      isActive1: false,
      isActive2: true,
      isActive3: false,
      isActive4: false,
      isActive5: true,
      isActive6: false,
      chart1: 2, //折线图1（2-月, 1-天）
      chart2: 2, //折线图2（2-月, 1-天）
      chart1XAxis: [], //折线图1的x轴数据
      chart1Series: [], //折线图1的series数据
      chart2XAxis: [], //折线图2的x轴数据
      chart2Series: [], //折线图2的series数据
      chart1XAxisShowMd: [], // 折线图1的x轴处理显示数据是月还是月+天
      chart2XAxisShowMd: [], //  折线图2的x轴处理显示数据是月还是月+天
      chartArr1: [],
      timer: null,
      chart1DefaultData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //用户折线图默认数据
      chart2DefaultData: [
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        }
      ], //服务折线图默认数据
      chart1Flag: false // 判断用户折线图使用有数据
    }
  },
  computed: {
    // id
    partnerId() {
      return JSON.parse(sessionStorage.getItem('typeMode')).id
    },
    // 判断当前是使用的什么智参（浏阳、佛山、合作伙伴）
    zcName() {
      return JSON.parse(sessionStorage.getItem('zcName'))
    }
  },
  created() {
    this.getCockpitData() //获取工作台数据
  },
  mounted() {
    this.getUserCurveChart(this.chart1) //动态获取折线图1的数据
    this.getServiceDataResult(this.chart2) //动态获取折线图2的数据

    this.timer = setInterval(() => {
      this.getUserCurveChart(this.chart1) //动态获取折线图1的数据
      this.getServiceDataResult(this.chart2) //动态获取折线图2的数据
    }, 3600000)

    window.addEventListener('resize', this.handleResize)
    this.handleResize() // 初始化时调用一次
  },
  destroyed() {
    clearInterval(this.timer)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // 折线图1
    renderEcharts1() {
      let echarts1 = this.$refs.echarts1
      let myChart = echarts.init(echarts1)
      let type = this.chart1
      let option

      // 所有的数据
      const chartArr = this.chartArr1
      const chart1Flag = this.chart1Flag

      option = {
        // 可以显示整个图表
        dataZoom: [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            start: 0,
            end: 60
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 10,
            end: 60
          }
        ],
        title: {
          subtext: '（用户数/人）', // 副标题文本
          left: 'left', // 标题水平居中
          top: 14,
          textStyle: {
            // 标题文本样式
          },
          subtextStyle: {
            // 副标题样式
            fontStyle: 'normal', // 字体风格
            // fontWeight: 'bold', // 字体粗细
            fontSize: 12, // 字体大小，可以根据需要调整
            color: '#333333' // 字体颜色
          }
        },
        color: '#4E93FB',
        // 实现 echarts 鼠标悬浮上去的效果
        tooltip: {
          trigger: 'axis',
          show: true, // 是否显示提示框，默认为 true
          // trigger: 'item', // 触发类型，可选值: 'item'（数据项触发），'axis'（坐标轴触发），'none'（不触发）
          backgroundColor: '#fff', // 提示框背景色
          padding: [3], // 内边距
          textStyle: {
            // 文本样式
            color: '#fff', // 文本颜色
            fontSize: 12 // 文本字号
          },
          // 悬浮框提示
          formatter(params) {
            if (!chart1Flag) {
              return
            }
            const userData = chartArr[params[0].dataIndex]
            const arr = userData.time.split('-')
            // 处理时间展示格式
            const time = arr[2] ? `${arr[0]}年${arr[1]}月${arr[2]}日` : `${arr[0]}年${arr[1]}月`
            return `
            <div style="width:145px; height:108px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
              <div style="display:flex; margin-bottom: 4px">
                <div style="width:32px; height:32px; background:#0066FF; border-radius: 4px; line-height:32px; text-align:center">
                  <i class="el-icon-share" style="font-size:16px; color: #fff"></i>
                </div>
                <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
                  <span style="font-size: 10px; color: #333; font-weight: 600;">平台累计用户数</span>
                  <span style="font-size: 10px; color: #333; font-weight: 400;">${userData.userNumTotal}</span>
                </div>
              </div>
              <div style="font-size: 10px; font-weight: 400; line-height: 14px; color: #999; padding:2px 0">
                ${time}累计用户（人）：
                <span style="color: #FDD003;">${userData.accumulatedNum}</span>
              </div>
              <div style="font-size: 10px; font-weight: 400; line-height: 14px; color: #999; padding:2px 0">
                ${time}新增用户（人）：
                <span style="color: #FDD003;">${userData.addNum}</span>
              </div>
            </div>`
          },
          // 辅助线
          axisPointer: {
            type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
            lineStyle: {
              color: '#1890FF', // 辅助线颜色
              type: 'dashed'
            },
            crossStyle: {
              // 十字准星指示器样式设置
              color: '#aaa' // 线条颜色
            },
            shadowStyle: {
              // 阴影指示器样式设置
              color: 'rgba(150,150,150,0.3)' // 阴影颜色
            }
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: '#333333' //Y轴内容文字颜色
            },
            interval: 0 // 设置为0表示全部显示x轴坐标
            // data: this.chartArr1.map((item) => {
            //   item.time
            // }),
            // formatter: function (params) {
            //   // var item = this.chart1XAxisShowMd
            //   // console.log(item, 'item111')
            //   // return this.chart1XAxisShowMd
            // }
          },
          boundaryGap: false,
          nameTextStyle: {
            verticalAlign: 'bottom',
            lineHeight: 10,
            backgroundColor: '#DCE9FE'
          },
          // data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
          // data: ['12', '11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1']
          // data: this.chart1XAxisShowMd,
          data:
            type == 2
              ? [
                  '1月',
                  '2月',
                  '3月',
                  '4月',
                  '5月',
                  '6月',
                  '7月',
                  '8月',
                  '9月',
                  '10月',
                  '11月',
                  '12月'
                ]
              : this.chart1XAxisShowMd
        },
        yAxis: {
          type: 'value',
          data: [0, 4000, 8000, 12000, 16000, 20000, 24000],
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
              color: '#333333', //Y轴内容文字颜色
              fontSize: 10 // 设置y轴标题文字大小为25
            }
          },
          // y轴分割线
          splitLine: {
            show: true, // 显示分隔线
            lineStyle: {
              type: 'dashed', // 设置分隔线为虚线
              color: '#E4E4E4'
            }
          },
          min: '0',
          max: type == 2 ? '60' : '10'
          // 设置y轴分割段数
          // splitNumber: 6
          // 设置y轴刻度间隔，例如设置为1，则刻度间隔为1
          // interval: 1
        },
        series: [
          {
            data: this.chart1Flag ? this.chartArr1.map((item) => item.num) : this.chart1DefaultData,
            type: 'line',
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },
            symbolSize: 0 // 设置拐点小圆点大小
          }
        ]
      }

      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 折线图2
    renderEcharts2() {
      var echarts2 = this.$refs.echarts2
      var myChart = echarts.init(echarts2)
      let type = this.chart2
      var option

      const industryNum = this.chart2Series.map((item) => item.industryNum)
      const personalNum = this.chart2Series.map((item) => item.personalNum)
      const planNum = this.chart2Series.map((item) => item.planNum)
      const planVideoNum = this.chart2Series.map((item) => item.planVideoNum)
      const policyNum = this.chart2Series.map((item) => item.policyNum)
      const productNum = this.chart2Series.map((item) => item.productNum)
      const sceneKnowledgeNum = this.chart2Series.map((item) => item.sceneKnowledgeNum)
      const sceneNum = this.chart2Series.map((item) => item.sceneNum)
      const serverNum = this.chart2Series.map((item) => item.serverNum)
      const techNum = this.chart2Series.map((item) => item.techNum)

      option = {
        // 可以显示整个图表
        dataZoom: [
          {
            type: 'slider',
            show: true,
            start: 0,
            end: 60,
            bottom: -2
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 10,
            end: 60,
            bottom: 0
          }
        ],
        title: {
          subtext: `资讯类型：\n\n（订单数/份）`, // 副标题文本
          // left: 'left', // 标题水平居中
          left: -5, // 标题水平居中
          top: -10,
          textStyle: {
            // 标题文本样式
          },
          subtextStyle: {
            // 副标题样式
            fontStyle: 'normal', // 字体风格
            // fontWeight: 'bold', // 字体粗细
            fontSize: 12, // 字体大小，可以根据需要调整
            color: '#333333' // 字体颜色
          }
        },
        tooltip: {
          trigger: 'axis',
          // 辅助线
          axisPointer: {
            type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
            lineStyle: {
              color: '#1890FF', // 辅助线颜色
              type: 'dashed'
            },
            crossStyle: {
              // 十字准星指示器样式设置
              color: '#aaa' // 线条颜色
            },
            shadowStyle: {
              // 阴影指示器样式设置
              color: 'rgba(150,150,150,0.3)' // 阴影颜色
            }
          }
        },
        color: [
          '#28C096',
          '#33E7FF',
          '#FF9533',
          '#FF3333',
          '#9933FF',
          '#FF33EB',
          '#94D93D',
          '#02D9A5',
          '#01B3FF',
          '#2501FF'
        ],
        legend: {
          left: 70,
          top: -4.5,
          textStyle: {
            fontSize: 12
          },
          data: [
            // {
            //   name: '应用场景',
            //   icon: 'rect',
            //   borderCap: 'round',
            //   lineStyle: {
            //     // width: '10px'
            //   }
            // },
            // {
            //   name: '数智技术',
            //   icon: 'rect'
            // },
            // {
            //   name: '行业动态',
            //   icon: 'rect'
            // },
            // {
            //   name: '案例视频',
            //   icon: 'rect'
            // },
            // {
            //   name: '数智产品',
            //   icon: 'rect'
            // },
            // {
            //   name: '数智服务商',
            //   icon: 'rect'
            // },
            // {
            //   name: '政策解读',
            //   icon: 'rect'
            // },
            // {
            //   name: '组织人才',
            //   icon: 'rect'
            // },
            // {
            //   name: '数据案例',
            //   icon: 'rect'
            // },
            // {
            //   name: '场景知识',
            //   icon: 'rect'
            // }
            '应用场景',
            '数智技术',
            '行业动态',
            '案例视频',
            '数智产品',
            '数智服务商',
            '政策解读',
            '组织人才',
            '数据案例',
            '场景知识'
          ]
        },
        // 设置这个会重叠
        // grid: {
        //   left: '3%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true
        // },
        toolbox: {},
        xAxis: {
          type: 'category',
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: '#333333' //Y轴内容文字颜色
            },
            interval: 0 // 设置为0表示全部显示x轴坐标
          },
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          boundaryGap: false,
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          // data: this.chart2XAxis
          // data: this.chart2XAxisShowMd
          data:
            type == 2
              ? [
                  '1月',
                  '2月',
                  '3月',
                  '4月',
                  '5月',
                  '6月',
                  '7月',
                  '8月',
                  '9月',
                  '10月',
                  '11月',
                  '12月'
                ]
              : this.chart2XAxisShowMd
        },
        yAxis: {
          type: 'value',
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
              color: '#333333', //Y轴内容文字颜色
              fontSize: 10 // 设置y轴标题文字大小为25
            }
          },
          // y轴分割线
          splitLine: {
            show: true, // 显示分隔线
            lineStyle: {
              type: 'dashed', // 设置分隔线为虚线
              color: '#E4E4E4'
            }
          },
          min: '0',
          max: type == 2 ? '150' : '20'
        },
        series: [
          {
            name: '应用场景',
            type: 'line',
            // stack: 'Total',
            data: industryNum
          },
          {
            name: '数智技术',
            type: 'line',
            // stack: 'Total',
            data: personalNum
          },
          {
            name: '行业动态',
            type: 'line',
            // stack: 'Total',
            data: planNum
          },
          {
            name: '案例视频',
            type: 'line',
            // stack: 'Total',
            data: planVideoNum
          },
          {
            name: '数智产品',
            type: 'line',
            // stack: 'Total',
            data: policyNum
          },
          {
            name: '数智服务商',
            type: 'line',
            // stack: 'Total',
            data: productNum
          },
          {
            name: '政策解读',
            type: 'line',
            // stack: 'Total',
            data: sceneKnowledgeNum
          },
          {
            name: '组织人才',
            type: 'line',
            // stack: 'Total',
            data: sceneNum
          },
          {
            name: '数据案例',
            type: 'line',
            // stack: 'Total',
            data: serverNum
          },
          {
            name: '场景知识',
            type: 'line',
            // stack: 'Total',
            data: techNum
          }
        ]
      }

      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 获取工作台数据
    async getCockpitData() {
      const res = await getCockpitData({
        partnerId: this.partnerId
      })
      if (res.data.resultCode == 200) {
        this.cockpitData = res.data.data
      }
    },
    // 切换年数据（折线图1）
    checkYearEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = true
      this.isActive2 = false
      this.isActive3 = false
      this.chart1 = 3
      this.getUserCurveChart(this.chart1)
    },
    // 切换月数据（折线图1）
    checkMonthEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = false
      this.isActive2 = true
      this.isActive3 = false
      this.chart1 = 2
      this.getUserCurveChart(this.chart1)
    },
    // 切换日数据（折线图1）
    checkDayEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = false
      this.isActive2 = false
      this.isActive3 = true
      this.chart1 = 1
      this.getUserCurveChart(this.chart1)
    },
    // 获取用户数据 (3年 2月 1日)
    async getUserCurveChart(type) {
      const res = await getUserCurveChart({
        type,
        partnerId: this.partnerId
      })
      if (res.data.resultCode == 200) {
        this.chart1XAxis = []
        this.chartArr1 = []
        // 如果折线图数据为空
        if (!res.data.data) {
          this.chart1Flag = false
          this.chart1XAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          this.chartArr1 = this.chart1DefaultData
        } else {
          this.chart1Flag = true
          // 所有的数据
          this.chartArr1 = res.data.data
          res.data.data.forEach((obj) => {
            this.chart1XAxis.push(obj.time)
            this.chart1Series.push(obj.num)
          })
          if (type == 2) {
            this.chart1XAxisShowMd = []
            this.chart1XAxis.forEach((mon) => {
              // this.chart1XAxisShowMd.push(month.split('-')[1])
              const month = mon.split('-')[1]
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart1XAxisShowMd.push(formattedMonth)
            })
          } else if (type == 1) {
            this.chart1XAxisShowMd = []
            this.chart1XAxis.forEach((md) => {
              const [year, month, day] = md.split('-')
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              const formattedDay = day.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart1XAxisShowMd.push(`${formattedMonth}/${formattedDay}`)
            })
          }
        }
        // 折线图1数据
        this.renderEcharts1()
      }
    },
    // 切换年数据（折线图2）
    checkYearEchart2() {
      this.chart2XAxis = []
      this.chart2Series = []
      this.chart2 = 3
      this.isActive4 = true
      this.isActive5 = false
      this.isActive6 = false
      this.getServiceDataResult(this.chart2)
    },
    // 切换月数据（折线图2）
    checkMonthEchart2() {
      this.chart2XAxis = []
      this.chart2Series = []
      this.isActive4 = false
      this.isActive5 = true
      this.isActive6 = false
      this.chart2 = 2
      this.getServiceDataResult(this.chart2)
    },
    // 切换日数据（折线图2）
    checkDayEchart2() {
      this.chart2XAxis = []
      this.chart2Series = []
      this.isActive4 = false
      this.isActive5 = false
      this.isActive6 = true
      this.chart2 = 1
      this.getServiceDataResult(this.chart2)
    },
    // 获取服务数据 (3年 2月 1日)
    async getServiceDataResult(type) {
      const res = await getServiceDataResult({
        type,
        partnerId: this.partnerId
      })
      if (res.data.resultCode == 200) {
        this.chart2XAxis = []
        // 如果折线图数据为空
        if (!res.data.data) {
          this.chart2XAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          this.chart2Series = this.chart2DefaultData
        } else {
          res.data.data.forEach((obj) => {
            this.chart2XAxis.push(obj.time)
          })
          this.chart2Series = res.data.data.map((obj) => {
            const { time, ...noTimeObj } = obj
            return noTimeObj
          })
          if (type == 2) {
            this.chart2XAxisShowMd = []
            this.chart2XAxis.forEach((mon) => {
              const month = mon.split('-')[1]
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart2XAxisShowMd.push(formattedMonth)
            })
          } else if (type == 1) {
            this.chart2XAxisShowMd = []
            this.chart2XAxis.forEach((md) => {
              const [year, month, day] = md.split('-')
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              const formattedDay = day.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart2XAxisShowMd.push(`${formattedMonth}/${formattedDay}`)
            })
          }
        }
        // 折线图2数据
        this.renderEcharts2()
      }
    },
    // 跳转诊断服务
    toDiagnosis() {
      this.$router.push({
        name: 'diagnosis'
      })
    },
    // 屏幕适配
    handleResize() {
      // 这里可以获取当前的视口大小，并根据需要调整元素的宽度
      const viewportWidth = window.innerWidth
      console.log(viewportWidth)
      // 假设我们想要在某个视口宽度下改变div的宽度
      if (viewportWidth < 1920) {
        if (viewportWidth > 1280) {
          // 恢复到使用视窗单位或其他默认样式
          this.$refs.divOne.style.width = '38vw'
          this.$refs.divTwo.style.width = '38vw'
        } else {
          this.$refs.divOne.style.width = '36vw' // 使用固定像素值或其他单位
          this.$refs.divTwo.style.width = '36vw'
        }
        // 订单数据图表
        this.$refs.addIcon_i.style.fontSize = '0.7rem'
        this.$refs.num.style.fontSize = '1rem'
        this.$refs.add.style.fontSize = '0.7rem'
        this.$refs.addNum.style.fontSize = '0.7rem'
        // 诊断数据图表
        this.$refs.addIcon_i1.style.fontSize = '0.7rem'
        this.$refs.num1.style.fontSize = '1rem'
        this.$refs.add1.style.fontSize = '0.7rem'
        this.$refs.addNum1.style.fontSize = '0.7rem'
        // 服务数据图表
        this.$refs.addIcon_i2.style.fontSize = '0.7rem'
        this.$refs.num2.style.fontSize = '1rem'
        this.$refs.add2.style.fontSize = '0.7rem'
        this.$refs.addNum2.style.fontSize = '0.7rem'
        // 用户数据图表
        this.$refs.addIcon_i3.style.fontSize = '0.7rem'
        this.$refs.num3.style.fontSize = '1rem'
        this.$refs.add3.style.fontSize = '0.7rem'
        this.$refs.addNum3.style.fontSize = '0.7rem'
        // 客户数据图表
        this.$refs.addIcon_i4.style.fontSize = '0.7rem'
        this.$refs.num4.style.fontSize = '1rem'
        this.$refs.add4.style.fontSize = '0.7rem'
        this.$refs.addNum4.style.fontSize = '0.7rem'
      } else {
        // 恢复到使用视窗单位或其他默认样式
        this.$refs.divOne.style.width = '41vw'
        this.$refs.divTwo.style.width = '41vw'
        // 订单数据图表
        this.$refs.addIcon_i.style.fontSize = '1rem'
        this.$refs.num.style.fontSize = '1.3rem'
        this.$refs.add.style.fontSize = '1rem'
        this.$refs.addNum.style.fontSize = '1rem'
        // 诊断数据图表
        this.$refs.addIcon_i1.style.fontSize = '1rem'
        this.$refs.num1.style.fontSize = '1.3rem'
        this.$refs.add1.style.fontSize = '1rem'
        this.$refs.addNum1.style.fontSize = '1rem'
        // 服务数据图表
        this.$refs.addIcon_i2.style.fontSize = '1rem'
        this.$refs.num2.style.fontSize = '1.3rem'
        this.$refs.add2.style.fontSize = '1rem'
        this.$refs.addNum2.style.fontSize = '1rem'
        // 用户数据图表
        this.$refs.addIcon_i3.style.fontSize = '1rem'
        this.$refs.num3.style.fontSize = '1.3rem'
        this.$refs.add3.style.fontSize = '1rem'
        this.$refs.addNum3.style.fontSize = '1rem'
        // 客户数据图表
        this.$refs.addIcon_i4.style.fontSize = '1rem'
        this.$refs.num4.style.fontSize = '1.3rem'
        this.$refs.add4.style.fontSize = '1rem'
        this.$refs.addNum4.style.fontSize = '1rem'
      }
    }
  }
}
</script>

<style lang="less" scoped>
// @media (max-width: 1900px) {
//   .num {
//     font-size: 1.3rem;
//   }

// }
.active {
  border: 1px 0px 0px 0px;
  background: #85b6ff;
  color: #fff;
  cursor: pointer;
}
.workbench {
  width: 100%;
  height: 50rem;
  background: #f6f8f9;
  box-sizing: border-box;
  // img {
  //   width: 303px;
  //   height: 303px;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  // .workbench_trips {
  //   position: absolute;

  //   color: #999999;
  //   font-size: 18px;
  //   top: 67%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    background: #fff;
    font-family: PingFang SC;
    border-radius: 4px;
    margin-bottom: 1%;
    .login_info {
      display: flex;
      align-items: center;
      padding-left: 16px;
      img {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        margin-right: 24px;
        display: flex;
        align-items: center;
      }
      .info {
        height: 78px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4px 0;
        box-sizing: border-box;
        .info_icon {
          width: 16px;
          height: 16px;
          color: #8ab8ff;
          margin-right: 6px;
        }
      }
      .info h2 {
        font-size: 24px;
        font-weight: 500;
        color: #151515;
        padding: 0;
        margin: 0;
      }
      .time span:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        color: #595959;
      }
    }
    .right {
      height: 78px;
      display: flex;
      .income {
        height: 100%;
        margin-right: 60px;
        .income_title {
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          text-align: left;
          color: #ff6b00;
          :nth-child(1) {
            width: 13px;
            height: 13px;
          }
        }
        .income_money {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.8px;
          text-align: left;
          color: #ff6b00;
          .money_icon {
            font-size: 28px;
            font-weight: 600;
            line-height: 44.8px;
            text-align: left;
          }
        }
        .income_order {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          color: #999;
        }
      }
      .reporting_success {
        height: 100%;
        margin-right: 60px;
        color: #09121f;
        .reporting_title {
          .report_icon {
            font-size: 13px;
          }
          .report_title {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
          }
        }
        .reporting_num {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.8px;
          span {
            font-size: 28px;
            font-weight: 600;
            line-height: 44.8px;
          }
        }
        .reporting_order {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #999;
        }
      }
    }
  }
  .dataTxt {
    height: 12%;
    // margin-top: 1%;
    ul {
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        width: 17.6%;
        // height: 100%;
        padding: 20px 0 20px 20px;
        display: flex;
        border-radius: 4px;
        background: #fff;
        .icon {
          width: 4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 5px;
          margin-right: 12px;
          i {
            width: 32px;
            height: 28px;
            font-size: 1rem;
          }
        }
        .data_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 1rem;
            font-weight: 600;
            line-height: 22.4px;
            color: #333;
          }
          .numInfo {
            .num {
              font-size: 1rem;
              font-weight: 600;
              line-height: 33.6px;
              color: #333;
            }
            .add {
              font-size: 0.7rem;
              font-weight: 400;
              line-height: 16.8px;
              color: #ff6c66;
              margin-left: 0.5rem;
            }
            .addNum {
              font-size: 0.7rem;
              font-weight: 400;
              line-height: 22.4px;
              color: #ff6c66;
              margin-left: 10px;
            }
            .addIcon {
              // width: 17px;
              // height: 17px;
              color: #ff6c66;
              margin-left: 0.2rem;
              i {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
    ul li:nth-child(1) .icon {
      background: #f28e7b;
    }
    ul li:nth-child(2) .icon {
      background: #1dbbff;
    }
    ul li:nth-child(3) .icon {
      background: #f4b667;
    }
    ul li:nth-child(4) .icon {
      background: #9a7bf2;
    }
    ul li:nth-child(5) .icon {
      background: #609bf0;
    }
  }
  .dataEcharts {
    height: 25rem;
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    .userData {
      width: 41vw;
      padding: 0 20px;
      background: #fff;
      padding: 18px 20px;
      border-radius: 8px;
      .data_title {
        display: flex;
        justify-content: space-between;
        .data {
          font-size: 0.8rem;
          font-weight: 600;
          line-height: 28px;
          color: #151515;
        }
        .date {
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 16.8px;
          color: #333;
          display: flex;
          div {
            display: inline-block;
            width: 27px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
          }
          // .active {
          //   border: 1px 0px 0px 0px;
          //   background: #85b6ff;
          //   color: #fff;
          //   cursor: pointer;
          // }
          .year {
          }
          .month {
            // background: #85b6ff;
            // color: #fff;
          }
          .day {
            width: 45px;
          }
        }
      }
      .data_echarts {
        height: 360px;
        overflow: hidden;
        height: 90%;
      }
    }
    .serviceData {
      border-radius: 8px;
      width: 41vw;
      padding: 18px 20px;
      background: #fff;
      .data_title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .data {
          font-size: 0.8rem;
          font-weight: 600;
          line-height: 28px;
          color: #151515;
        }
        .date {
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 16.8px;
          color: #333;
          display: flex;
          div {
            display: inline-block;
            width: 27px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
          }
          // div:hover {
          //   border: 1px 0px 0px 0px;
          //   background: #85b6ff;
          //   color: #fff;
          // }
          .year {
          }
          .month {
            // background: #85b6ff;
            // color: #fff;
          }
          .day {
            width: 45px;
          }
        }
      }
      .data_echarts {
        height: 340px;
        overflow: hidden;
        height: 84%;
      }
    }
  }
  .quickEntrance {
    width: 100%;
    height: 18%;
    background: #fff;
    cursor: pointer;
    border-radius: 4px;
    padding-bottom: 10px;
    h2 {
      width: 100%;
      height: 7%;
      font-size: 1rem;
      font-weight: 600;
      line-height: 28px;
      padding-left: 20px;
      padding-top: 1%;
    }
    .content {
      width: 100%;
      height: 8%;
      display: flex;
      padding: 1% 0 1% 40px;
      align-items: flex-start;
      .icon {
        width: 61px;
        height: 61px;
        background: linear-gradient(180deg, #0066ff 0%, #0038ff 100%);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        i {
          // width: 24px;
          // height: 24px;
          font-size: 24px;
          color: #fff;
        }
      }
      .service {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .service span:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #333;
      }
      .service span:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: #9b9a9a;
        i {
          color: #4e93fb;
          font-style: normal;
        }
      }
    }
  }
}
</style>
